:root {
  --color-text-main: #000000;
  --color-white: #fff;
  --color-ECHBLUE: #0083c1;
  --color-FOCUSBLUE: #0083c1;
  --color-DISABLEBLUE: #0083c1;
  --color-ECHDARKBLUE: #002a3a;
  --color-ECHLIGHTBLUE: #dcf6fe;
  --color-COPYTEXT: #707070;
  --max-width: 1280px;
  --gutter-margin: 30px;
  --image-margin: 3rem;
  --font: "Rubik";
  --font-header: "Bebas Neue";
  --halftone-e: url(https://cdn.shopify.com/s/files/1/2422/9487/files/low-opacity-e-file.png?v=1634831974);
  --header-height: 142px;
  --card-shadow: 0px 4px 24px -1px #dadada;
  --card-border-radius: 15px;
}

body {
  margin: 0;
  font-family: "Rubik", sans-serif;
}

/* #card-noplan{
  display: none;
} */

.buy-button {
  border: none;
}

.container {
  margin: auto;
}

.dark {
  background-color: #ececed;
}

p {
  color: var(--color-COPYTEXT);
  line-height: 1.625;
}

.promo-banner-container {
  padding: 8px;
  background-color: var(--color-ECHBLUE);
  color: #fff;
  text-align: center;
}

.banner {
  background-color: #000000;
  color: #fff;
  padding: 4px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* .banner .logo {
  min-width: 175px;
} */

.hero-header {
  background-color: #f8f8f8;
  padding: 16px;
}

.hero-title-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 50px;
  margin: 0 auto;
  max-width: 1200px;
}

.special-message {
  text-align: center;
  color: var(--color-ECHBLUE);
}

.partner-hero-title h2 span span {
  font-size: 38px;
  font-weight: 400;
}

.sub-header {
  text-transform: capitalize;
}

.cart {
  padding: 4px 8px;
  background-color: #000000;
  border-radius: 20px;
  width: 40px;
  height: 40px;
  position: fixed;
  bottom: 36px;
  right: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.cartIcon {
  background: #fff;
  border-radius: 999px;
  color: var(--color-ECHBLUE);
  font-size: 70%;
  font-weight: 700;
  line-height: .75em;
  min-width: 1em;
  padding: 0.6em;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  animation: pulse-animation 2s infinite;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
  }
}

.special-text{
  color:red;
}

#gympassy{
  display:none;
}

.cart-container {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: #00000038;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cart-wrapper {
  background-color: #fff;
  padding: 1em;
  max-width: 650px;
}

.cart-wrapper ul {
  padding: 0;
  list-style: none;
  max-height: 60vh;
  overflow-y: auto;
}

.cart-item {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 24px;
  flex-wrap: nowrap;
  padding: 16px 0;
  border-bottom: 1px solid #7070702e;
}

.cart-item img {
  width: 20%;
  height: auto;
}

.cart-item .item-price-container {
  display: flex;
  gap: 16px;
  flex-grow: 1;
  /* justify-content: space-between; */
  justify-content: right;
  padding-right: 4px;
}

.cart-item .item-title {
  justify-content: left;
  display: flex;
  flex: 1;
}

.totals {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.totals p {
  margin: 0;
}

.totals p:first-child {
  color: rgb(239, 68, 68);
}

.totals span {
  font-size: 12px;
}

.cart-item .item-price {
  color: rgb(239, 68, 68);
}

.cart-item .compareAt-price {
  text-decoration: line-through;
  color: var(--color-COPYTEXT);
  margin-left: 8px;
}

.cart-btns-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
  gap: 16px;
}

.cart-btns-container button {
  padding: 1em;
  border: none;
  cursor: pointer;
}

.cart-btns-container .clear-cart {
  background-color: #fff;
}

.cart-btns-container .checkout {
  color: #fff;
  background-color: var(--color-ECHBLUE);
}

.close-cart-btn-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.cart-item{
  position: relative;
}

.ipadProd {
position: absolute;
visibility: hidden;
}

.ipadProd:before {
  position: relative;
content: "Free Gift!";
visibility: visible;
}

.ipadProd + span{
position: relative;
visibility: hidden;
}

.close-cart-btn {
  text-align: right;
  cursor: pointer;
}

.close-cart-btn svg {
  width: 2em;
}

.membership-container {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: #00000038;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.membership-wrapper {
  background-color: #fff;
  height: fit-content;
}

.membership-cards-header {
  text-align: right;
  background-color: var(--color-ECHBLUE);
  padding: 1em;
  color: #fff;
}

.cards-header div {
  text-align: left;
}

.cards-wrapper {
  justify-content: center;
  align-items: center;
  height: 85vh;
  overflow-y: scroll;
}

.membership-card {
  width: 98%;
  /* align-self: stretch; */
  border: 1px solid rgba(0, 0, 0, 0.459);
  display: flex;
  flex-direction: column;
  margin: 16px auto;
  background-color: #eff9fc;
}

.membership-card .card-header {
  height: 150px;
  padding: 16px;
  background-color: #eff9fc;
  position: relative;
}

.membership-card .card-header h3 {
  margin-top: 0;
}

.membership-card .card-header .tag {
  position: absolute;
  top: 8px;
  right: 16px;
  border-radius: 15px;
  padding: 8px 16px;
  font-size: 12px;
}

.membership-card .card-header .most-popular {
  background-color: #0083c1;
  color: #fff;
}

.membership-card .card-header .best-value {
  background-color: #002A3A;
  color: #fff;
}

.membership-card .card-body {
  /* flex-grow: 1; */
  padding: 1em;
}

.membership-card .card-content {
  display:none;
}

.membership-card .card-footer {
  padding: 1em;
}

.membership-card .card-footer button {
  width: 100%;
  border: none;
}

.credera-section .credera-snippet.primary-button {
  background-color:var(--color-ECHBLUE);
  color:#fff;
  display:inline-block;
  font-family:var(--font),sans-serif;
  font-size:16px;
  font-style:normal;
  font-weight:500;
  line-height:20px;
  padding:20px 24px;
  transition:background-color .3s ease;
  box-shadow:0 2px 5px rgba(0,0,0,.3),0 5px 5px rgba(0,0,0,.22)
 }

 .credera-snippet.primary-button.disabled {
  background-color:#707070
 }
 .credera-snippet.primary-button * {
  color:#fff
 }
 .credera-snippet.primary-button .button-content {
  display:flex
 }
 .credera-snippet.primary-button .button-content svg {
  align-self:center;
  height:20px;
  margin-left:24px;
  width:20px;
  transition:transform .3s ease
 }
 .credera-snippet.primary-button:hover .button-content svg {
  transform:translateX(2px)
 }

 .credera-snippet.primary-button .button-content {
  justify-content:space-between
 }

.product-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 32px;
  flex-wrap: wrap;
  padding: 16px;
  max-width: 1400px;
  margin: auto;
}

.product-image-wrapper img {
  width: 100%;
  height: 100%;
  max-height: 450px;
}

.product-info-wrapper h2 {
  font-weight: 400;
  letter-spacing: 3px;
  font-size: 16px;
  margin: 0;
}

.product-info-wrapper h3 {
  font-weight: 400;
  font-size: 40px;
  margin: 0;
}

.price-container .price-actual {
  font-weight: 400;
  font-size: 1.5em;
}

.price-container .compare-price {
  margin-left: 8px;
  text-decoration: line-through;
  font-weight: 300;
  font-size: 1.5em;
  color: #707070;
}

.button-container {
  margin-top: 24px;
}

.button-wrapper {
  display: flex;
}

.button-wrapper input {
  padding: 22px 0px 22px 22px;
  max-width: 30px;
  border: 2px solid gray;
  box-shadow: 0 2px 5px rgb(0 0 0 / 30%), 0 5px 5px rgb(0 0 0 / 22%);
}

.buy-button {
  background-color: var(--color-ECHBLUE);
  color: #fff;
  display: inline-block;
  font-family: var(--font), sans-serif;
  /* font-size: 16px; */
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  padding: 20px 24px;
  transition: background-color 0.3s ease;
  box-shadow: 0 2px 5px rgb(0 0 0 / 30%), 0 5px 5px rgb(0 0 0 / 22%);
  cursor: pointer;
}

.buy-button svg {
  align-self: center;
  height: 20px;
  margin-left: 24px;
  width: 20px;
  transition: transform 0.3s ease;
}

.partner-hero-title {
  width: 100%;
  max-width: 500px;
}

.partner-hero-title h2 {
  font-weight: 600;
  font-size: 42px;
  text-transform: uppercase;
}

.hero-logo-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 16px;
  width: fit-content;
  flex-grow: 1;
}

.hero-logo-wrapper img {
  width: 100%;
  max-width: 400px;
  height: auto;
}

.hero-logo-wrapper strong {
  font-size: 42px;
  font-weight: 600;
}

.option-button {
  width: 26px;
  height: 26px;
  border-radius: 9999px;
  border-width: 2px;
  border-color: #000;
  margin-left: 0.25rem;
  margin-right: 0.25rem;
  cursor: pointer;
}

.color-Blue {
  background-color: #c9eef5;
}

.color-Purple {
  background-color: #c2c3e9;
}

.color-Orange {
  background-color: #ffd8ba;
}

.color-Pink {
  background-color: #e52d87;
}

.color-Black {
  background-color: #000;
}

.color-Red {
  background-color: #d22d35;
}

.color-White {
  background-color: #fff;
}

.option-active {
  border-width: 4px;
  border-color: var(--color-ECHBLUE);
  height: 28px;
  width: 28px;
}

.not-active {
  border-width: 2px;
  border-color: #000;
}

.row {
  flex-direction: row;
}

.reverse {
  flex-direction: row-reverse;
}

.footer-container {
  background-color: #002a3a;
  color: #fff;
}

.inner-footer {
  max-width: 1200px;
  padding: 1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.inner-footer .footer-block {
  min-width: 35%;
}

.credera-snippet.social-icons {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.credera-snippet.social-icons li {
  align-self: center;
  margin: 0 24px 0 0;
}

.inner-footer .footer-block a {
  color: #fff;
  text-decoration: none;
  font-weight: 300;
  line-height: 1.75rem;
}

.inner-footer .footer-block h4 {
  font-weight: 500;
}

.credera-snippet.social-icons svg {
  height: 24px;
}

.sub-footer {
  background-color: #00202d;
  color: #fff;
  padding-bottom: 100px;
}

.details {
  color: #fff;
  font-size: .875rem;
  line-height: 1.25rem;
  padding: 32px;
}

.links-list-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.links-list {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0;
}

.links-list li a {
  color: #fff;
}

.copyright {
  text-align: center;
  color: #fff;
  font-size: 12px;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.sub-footer .container {
  height: 100%;
  margin: auto;
  max-width: 1440px;
  width: 100%;
}

@media (max-width:568px) {
  .credera-snippet.primary-button {
   display:block;
   font-size:14px;
   text-align:center;
   width:100%
  }
  .credera-snippet.primary-button .button-content span {
   flex:1;
   margin-left:32px
  }
}

@media (min-width: 768px) {
  .membership-container {
    align-items: center;
  }

  .membership-cards-container {
    overflow-y: scroll;
    height: 80vh; 
  }

  .cards-wrapper {
    display: flex;
    gap: 16px;
    padding: 2em;
    overflow-y: unset;
    height: 100%;
  }

  .membership-card {
    margin: 0;
    /* height: 98vh; */
  }

  .inner-footer {
    flex-wrap: nowrap;
  }

  .links-list {
    flex-direction: row;
  }

  .footer-block.spacer {
    display: none;
  }
}

@media (min-width: 1024px) {
  .hero-header {
    padding: 32px;
  }

  .product-info-wrapper {
    max-width: 60%;
  }

  .product-container {
    flex-wrap: nowrap;
  }

  .membership-cards-container {
    max-height: 600px;
  }

  .membership-card {
    width: 250px;
    max-height: 600px;
  }
}

#card-30135749181522{
  height: 107vh;
}

#card-43246953267399{
  height: 107vh;
}

#card-30135749771346, #card-32930539503698 {
  height: fit-content;
}


#add-32930539503698, #add-30135749771346{
  position: relative;
  top: -25px;
}


/* Cart Scrollbar */
/* width */
.cart-wrapper ul::-webkit-scrollbar {
  width: 2px;
}

/* Track */
.cart-wrapper ul::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.cart-wrapper ul::-webkit-scrollbar-thumb {
  background: var(--color-ECHBLUE);
}

/* Handle on hover */
.cart-wrapper ul::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Spinner */
.adding-to-cart-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00000038;
}

.loader {
  margin: 100px auto;
  font-size: 25px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  -webkit-animation: load5 1.1s infinite ease;
  animation: load5 1.1s infinite ease;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
@-webkit-keyframes load5 {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #ffffff, 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.5), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.7), 1.8em -1.8em 0 0em #ffffff, 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.5), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7), 2.5em 0em 0 0em #ffffff, 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5), 2.5em 0em 0 0em rgba(255, 255, 255, 0.7), 1.75em 1.75em 0 0em #ffffff, 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.5), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.7), 0em 2.5em 0 0em #ffffff, -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.5), 0em 2.5em 0 0em rgba(255, 255, 255, 0.7), -1.8em 1.8em 0 0em #ffffff, -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.5), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.7), -2.6em 0em 0 0em #ffffff, -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.5), -2.6em 0em 0 0em rgba(255, 255, 255, 0.7), -1.8em -1.8em 0 0em #ffffff;
  }
}
@keyframes load5 {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #ffffff, 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.5), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.7), 1.8em -1.8em 0 0em #ffffff, 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.5), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7), 2.5em 0em 0 0em #ffffff, 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5), 2.5em 0em 0 0em rgba(255, 255, 255, 0.7), 1.75em 1.75em 0 0em #ffffff, 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.5), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.7), 0em 2.5em 0 0em #ffffff, -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.5), 0em 2.5em 0 0em rgba(255, 255, 255, 0.7), -1.8em 1.8em 0 0em #ffffff, -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.5), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.7), -2.6em 0em 0 0em #ffffff, -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.5), -2.6em 0em 0 0em rgba(255, 255, 255, 0.7), -1.8em -1.8em 0 0em #ffffff;
  }
}
.footerLink{
  color: #FFF;
}
